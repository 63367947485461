import React from "react"
import Four04 from '../../assets/images/404.svg'
import CatoLink from '../../components/cato-link';
import CatoImage from '../../components/cato-image';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import '../../assets/styles/pages/404.scss';

const NotFoundPage = () => (
    <Layout className="not-found-page calc" lang={'EN'} transparentMenu={true}>
        <SEO title="404: Not found" />
        <section className="not-found-page-wrapper">
            <h1>Page not found</h1>
            <p>Oops, Looks like your report link expired. If you would like to generate a new report, <br/>please use the calculator <CatoLink path={'/last-mile-calculator'}>here</CatoLink>.</p>
                <p>For a custom quote please <CatoLink path={'/contact-us'}>contact us</CatoLink></p>
            <div style={{zIndex: -1}} className="img-wrapper">
                <CatoImage img={Four04}/>
            </div>
        </section>
    </Layout>
)

export default NotFoundPage
